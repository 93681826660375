var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        { ref: "ruleForm", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c("BaseMessage", {
            attrs: { form: _vm.form, "shift-type": _vm.shiftType },
            on: { change: _vm.typeChange },
          }),
          _c("TimeSetting", { attrs: { form: _vm.form } }),
          _c("FlexSetting", {
            attrs: { form: _vm.form, "shift-type": _vm.shiftType },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }